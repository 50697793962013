import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ICountbarData } from "../../interface/common.interface";

@Component({
	selector: "app-count-bar",
	templateUrl: "./count-bar.component.html",
	styleUrls: ["./count-bar.component.scss"],
})
export class CountBarComponent implements OnChanges {
	@Input() receivedData: ICountbarData;
	isLoading: boolean = true;

	constructor() {}
	ngOnChanges(changes: SimpleChanges): void {
		this.receivedData = changes.receivedData.currentValue;
		if (this.receivedData) {
			this.isLoading = false;
		}
	}
	getPercentage(val: number) {
		if (this.receivedData.name === "Roadtax") {
			return (
				val /
				(this.receivedData.totalQuotation + this.receivedData.previousDays)
			);
		}
		return val / this.receivedData.totalQuotation;
	}
}
