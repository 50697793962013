<div fxFlex class="card-container" *ngIf="receivedData.filterSelected === 'today' || receivedData.filterSelected === 'Today' ">
<mat-card fxLayoutAlign=" center">
        <ng-container>
            <div fxFlex>
                <div class="fs-md fw-semi-b">
                    {{ receivedData.previousDays | number }}
                    <span class="fs-sm" *ngIf="receivedData.totalQuotation"
                    >({{ getPercentage(receivedData.previousDays) | percent }})</span
                    >
                </div>
                <div class="desc-f">Previous Days</div>
            </div>
            <div class="progress-spinner red">
                <mat-progress-spinner
                        mode="determinate"
                        [value]="getPercentage(receivedData.previousDays) * 100"
                >
                </mat-progress-spinner>
                <mat-progress-spinner class="bg" mode="determinate" value="100">
                </mat-progress-spinner>
                <mat-icon>error</mat-icon>
            </div>
        </ng-container>
        <ngx-skeleton-loader
                count="1"
                *ngIf="isLoading"
                [theme]="{
						'border-radius': '15px',
						height: '30px',
						'animation-duration': '1s'
					}"
        >
        </ngx-skeleton-loader>
    </mat-card>
</div>

<div fxFlex class="card-container">
    <mat-card fxLayoutAlign=" center">
        <ng-container>
            <div fxFlex>
                <div class="fs-md fw-semi-b">
                    {{ receivedData.before6 }}
                    <span class="fs-sm" *ngIf="receivedData.before6"
                    >({{ getPercentage(receivedData.before6) | percent }})</span
                    >
                </div>
                <div class="desc-f">Before 6PM</div>
            </div>
            <div class="progress-spinner green">
                <mat-progress-spinner
                        mode="determinate"
                        [value]="getPercentage(receivedData.before6) * 100"
                >
                </mat-progress-spinner>
                <mat-progress-spinner class="bg" mode="determinate" value="100">
                </mat-progress-spinner>
                <mat-icon>check_circle</mat-icon>
            </div>
        </ng-container>
        <ngx-skeleton-loader
                count="1"
                *ngIf="isLoading"
                [theme]="{
						'border-radius': '15px',
						height: '30px',
						'animation-duration': '1s'
					}"
        >
        </ngx-skeleton-loader>
    </mat-card>
</div>

<div fxFlex class="card-container">
    <mat-card fxLayoutAlign=" center">
        <ng-container>
            <div fxFlex>
                <div class="fs-md fw-semi-b">
                    {{ receivedData.after6 }}
                    <span class="fs-sm" *ngIf="receivedData.after6"
                    >({{ getPercentage(receivedData.after6) | percent }})</span
                    >
                </div>
                <div class="desc-f">After 6PM</div>
            </div>
            <div class="progress-spinner orange">
                <mat-progress-spinner
                        mode="determinate"
                        [value]="getPercentage(receivedData.after6) * 100"
                >
                </mat-progress-spinner>
                <mat-progress-spinner class="bg" mode="determinate" value="100">
                </mat-progress-spinner>
                <mat-icon>watch_later</mat-icon>
            </div>
        </ng-container>
        <ngx-skeleton-loader
                count="1"
                *ngIf="isLoading"
                [theme]="{
						'border-radius': '15px',
						height: '30px',
						'animation-duration': '1s'
					}"
        >
        </ngx-skeleton-loader>
    </mat-card>
</div>

<div fxFlex>
    <mat-card fxLayoutAlign=" center" class="focus-card">
        <div fxFlex>
            <div class="fs-md fw-semi-b color-white">
                {{ receivedData.totalQuotation }}
            </div>
            <div class="desc-f color-rev-sub-text">Total {{ this.receivedData.name === "Roadtax" ? "Records" : this.receivedData.name }}
            </div>
        </div>
        <div class="icon-circle">
            <mat-icon>assignment</mat-icon>
        </div>

        <ngx-skeleton-loader
                count="1"
                *ngIf="isLoading"
                [theme]="{
						'border-radius': '15px',
						height: '30px',
						'animation-duration': '1s'
					}"
        >
        </ngx-skeleton-loader>
    </mat-card>
</div>