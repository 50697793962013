import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import {
  CommonSelectionInterface,
  VehicleInterface,
} from "../../../../app/share/interface/common.interface";
import {
  MotorQuotationChangeOwnershipDetailInterface,
  MotorQuotationDetailInterface,
  MotorQuotationLinkListingInterface,
  MotorQuotationNCDInfo,
  MotorQuotationPolicyDetailListingInterface,
  MotorQuotationPolicyInterface,
  MotorQuotationPolicyListingInterface,
  MotorQuotationUpdateNcd,
  MotorQuotationVehicleInfo,
} from "../../../../app/share/interface/motor/motor-quotation.interface";
import {
  PromoCodeListingInterface,
  UploadStatusListingInterface,
} from "../../_interfaces/promoCode.interface";

@Injectable({
  providedIn: "root",
})
export class PromoCodeMotorService {
  private basePath: string = `${environment.url}/api/motor/MotorPromoCode/`;

  constructor(private http: HttpClient) {}

  getListing(
    limit: number,
    offset: number,
    text: string,
    columnIndex: number,
    sortDirection: string,
    creationStartDate: Date,
    creationEndDate: Date,
    effectiveStartDate: Date,
    effectiveEndDate: Date,
    statusList: string[],
  ) {
    return this.http.post<{
      count: number;
      data: PromoCodeListingInterface[];
    }>(`${this.basePath}GetListing`, {
      limit,
      offset,
      text,
      columnIndex,
      sortDirection,
      creationStartDate,
      creationEndDate,
      effectiveStartDate,
      effectiveEndDate,
      statusList,
    });
  }
  getStatusList() {
    return this.http.get(`${this.basePath}GetPromoCodeStatusSelection`);
  }

  downloadTemplate() {
    return this.http.get(`${this.basePath}DownloadPromoCodeExcelTemplate`, {
      responseType: "blob",
    });
  }

  deactivate(id: string) {
    return this.http.post(`${this.basePath}Deactivate/${id}`, null);
  }

  getUploadListing(
    columnIndex: number,
    sortDirection: string,

  ) {
    return this.http.post<UploadStatusListingInterface>(
      `${this.basePath}GetUploadStatusListing`,
      {
        columnIndex,
        sortDirection,
      
      },
    );
  }

  getPromoCodeExcel() {
    return this.http.post(`${this.basePath}UploadPromoCodeExcel`, null);
  }

  uploadExcel(file: any) {
    return this.http.post(`${this.basePath}UploadPromoCodeExcel`, file, {
      responseType: "blob",
    });
  }

  getListingExcel(
    limit: number,
    offset: number,
    text: string,
    columnIndex: number,
    sortDirection: string,
    creationStartDate: Date,
    creationEndDate: Date,
    effectiveStartDate: Date,
    effectiveEndDate: Date,
    statusList: string[],
  ) {
    return this.http.post(
      `${this.basePath}GetListingExcel
			`,
      {
        limit,
        offset,
        text,
        columnIndex,
        sortDirection,
        creationStartDate,
        creationEndDate,
        effectiveStartDate,
        effectiveEndDate,
        statusList,
      },
      {
        responseType: "blob",
      },
    );
  }
}
