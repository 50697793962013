import { Component, OnInit, ViewChild } from "@angular/core";
import { async, combineLatest, Observable, Subject, Subscription } from "rxjs";
import { CommonSelectionInterface } from "src/app/share/interface/common.interface";
import { debounceTime, map, takeUntil, tap } from "rxjs/operators";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import * as moment from "moment";
import { LoadingService } from "src/app/share/service/loading.service";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { FunctionPermissionsService } from "src/app/share/service/function-permission.service";
import { DateFormValue } from "src/app/share/constants/constants";
import { AlertService } from "src/app/share/service/alert.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ConfirmationDialog,
  ConfirmationDialogModel,
} from "src/app/share/dialog/confirmation-dialog/confirmation.dialog";
import { UploadFileComponent } from "src/app/promo-code-generation/_components/upload-file/upload-file.component";
import { MatDivider } from "@angular/material/divider";
import { StatusDialogComponent } from "src/app/promo-code-generation/_components/status-dialog/status-dialog.component";
import { triggerFileDownload } from "src/app/share/helpers/downloadFile.helper";
import {
  PromoCodeListingInterface,
  StatusTrackerModel,
} from "src/app/promo-code-generation/_interfaces/promoCode.interface";
import { PromoCodeMotorService } from "./services/promo-code-motor.service";

@Component({
  selector: "app-promo-code-motor",
  templateUrl: "./motor.component.html",
  styleUrls: ["./motor.component.scss"],
})
export class PromoCodeMotorComponent {
  searchChange: Subject<void> = new Subject<void>();
  endDate: Date;
  startDate: Date;
  isLoading: boolean = false;
  length = 0;
  selectedFilter: string;
  creationStartDate: Date;
  creationEndDate: Date;
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  status: string[] = [];

  displayedColumns: string[] = [
    "creationDate",
    "businessType",
    "promoName",
    "promoCode",
    "effectiveStartDate",
    "effectiveEndDate",
    "quantity",
    "discountPercent",
    "discountAmount",
    "status",
    "remarks",
    "action",
  ];
  filterForm: any = {
    creationDate: null,
    effectiveDate: null,
    effectiveStartDate: null,
    effectiveEndDate: null,
    searchTerm: "",
    status: [],
  };

  selected: PromoCodeListingInterface;
  filterSelected: "today" | "week" | "month" | "year" | "all" | "custom" =
    "all";
  lastFilterSelected: "today" | "week" | "month" | "year" | "all" | "custom" =
    "all";

  searchText: string;
  sortIndex: number = 0;
  sortDirection: "asc" | "desc" = "desc";
  limit: number = 50;
  offset: number = 1;
  list: PromoCodeListingInterface[] = [];

  filterTouched: boolean = false;
  statusOptions: string[] = [];
  selectedStatus: string[] = this.statusOptions;
  filterStatusList: string[] = this.statusOptions;
  constructor(
    private promoCodeMotorService: PromoCodeMotorService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
  ) {
    this.getStatusList();
    if (this.statusOptions.length > 0) {
      this.getData();
    }
    this.resetDate();
    this.searchChange.pipe(debounceTime(500)).subscribe(() => {
      this.filter();
    });
  }

  filter() {
    this.offset = 1;
    this.getData();
  }

  searchChanged() {
    if (this.searchText) this.searchChange.next();
  }

  startDateClose() {
    if (this.startDate == null) {
      this.filterSelected = this.lastFilterSelected;
      switch (this.filterSelected) {
        case "today":
          this.setTodayDate();
          break;
        case "week":
          this.setCurrentWeekDate();
          break;
        case "month":
          this.setCurrentMonthDate();
          break;
        case "year":
          this.setCurrentYearDate();
          break;
        case "all":
          this.resetDate();
          break;
      }
    }
  }

  setTodayDate(hasFilter: boolean = true) {
    this.startDate = new Date();
    this.endDate = new Date();
    this.setDefaultTimeRange();
    if (hasFilter) {
      this.filter();
    }
  }

  setCurrentWeekDate() {
    this.startDate = moment().startOf("week").toDate();
    this.endDate = moment().endOf("week").toDate();
    this.setDefaultTimeRange();
    this.filter();
  }

  setCurrentMonthDate() {
    this.startDate = moment().startOf("month").toDate();
    this.endDate = moment().endOf("month").toDate();
    this.setDefaultTimeRange();
    this.filter();
  }

  setCurrentYearDate() {
    this.effectiveStartDate = moment().startOf("year").toDate();
    this.effectiveEndDate = moment().endOf("year").toDate();

    this.setDefaultTimeRange();
    this.filter();
  }

  resetDate() {
    this.startDate = null;
    this.endDate = null;
    this.filter();
  }

  endDateClose() {
    if (this.endDate == null) {
      this.endDate = this.startDate;
    }
    this.filter();
  }

  resetTextFilter() {
    this.searchText = null;
    this.filter();
  }

  private setDefaultTimeRange() {
    if (this.creationStartDate && this.creationEndDate) {
      this.creationStartDate.setHours(0, 0, 0, 0);
      this.creationEndDate.setHours(23, 59, 59, 999);
    }
    if (this.effectiveStartDate && this.effectiveEndDate) {
      this.effectiveStartDate.setHours(0, 0, 0, 0);
      this.effectiveEndDate.setHours(23, 59, 59, 999);
    }
  }

  changeDate(type: string, range: DateFormValue) {
    switch (range) {
      case "Year":
        if (type === "CREATION") {
          this.creationEndDate = moment().endOf("year").toDate();
          this.creationStartDate = moment().startOf("year").toDate();
          this.filterForm.creationDate = "Year";
        } else {
          this.effectiveStartDate = moment().startOf("year").toDate();
          this.effectiveEndDate = moment().endOf("year").toDate();

          this.filterForm.effectiveDate = "Year";
        }
        break;
      case "Month":
        if (type === "CREATION") {
          this.creationEndDate = moment().endOf("month").toDate();
          this.creationStartDate = moment().startOf("month").toDate();
          this.filterForm.creationDate = "Month";
        } else {
          this.effectiveEndDate = moment().endOf("month").toDate();
          this.effectiveStartDate = moment().startOf("month").toDate();
          this.filterForm.effectiveDate = "Month";
        }

        break;
      case "Week":
        if (type === "CREATION") {
          this.creationStartDate = moment().startOf("week").toDate();
          this.creationEndDate = moment().endOf("week").toDate();

          this.filterForm.creationDate = "Week";
        } else {
          this.effectiveEndDate = moment().endOf("week").toDate();
          this.effectiveStartDate = moment().startOf("week").toDate();
          this.filterForm.effectiveDate = "Week";
        }

        break;
      case "Today":
        if (type === "CREATION") {
          this.creationStartDate = new Date();
          this.creationEndDate = new Date();
          this.filterForm.creationDate = "Today";
        } else {
          this.effectiveStartDate = new Date();
          this.effectiveEndDate = new Date();
          this.filterForm.effectiveDate = "Today";
        }

        break;
      case "All":
        if (type === "CREATION") {
          this.creationStartDate = null;
          this.creationEndDate = null;
          this.filterForm.creationDate = null;
        } else {
          this.effectiveStartDate = null;
          this.effectiveEndDate = null;
          this.filterForm.effectiveDate = null;
        }
        break;
    }

    this.setDefaultTimeRange();

    this.selectedFilter = range;
    this.filter();
  }

  resetDateFilter(field: string) {
    event.stopImmediatePropagation();
    if (field === "creationDate") {
      this.filterForm.creationDate = "All";
      this.creationEndDate = null;
      this.creationStartDate = null;
      this.changeDate("CREATION", "All");
    } else if (field === "effectiveDate") {
      this.effectiveEndDate = null;
      this.effectiveStartDate = null;
      this.filterForm.effectiveDate = "All";
      this.changeDate("EFFECTIVE", "All");
    }
  }

  onStartDateOpen() {
    this.startDate = null;
    this.endDate = null;
  }

  onStartDateSelected(type: string, val: Date) {
    this.filterForm.date = "Custom";
    if (type === "CREATION") {
      this.creationStartDate = val;
    } else {
      this.effectiveStartDate = val;
    }
  }

  onEndDateSelected(type: string, val: Date) {
    if (type === "CREATION") {
      this.creationEndDate = new Date(val.setHours(23, 59, 59, 999));
    } else {
      this.effectiveEndDate = new Date(val.setHours(23, 59, 59, 999));
    }
  }

  onDateRangePickerClosed(type: string) {
    if (this.creationStartDate && this.creationEndDate) {
      this.getData();
    } else if (this.effectiveStartDate && this.effectiveEndDate) {
      this.getData();
    } else {
      this.filterForm.date = "All";
      this.changeDate(type, "All");
    }
  }
  // * FILTER BY INSURER/DEALER *

  isAllOptionsSelected(
    keyName: FilterKeyName,
    selectionList: CommonSelectionInterface[],
  ) {
    return this[keyName].length == selectionList.length;
  }

  isSelectedOptionsIndeterminate(
    keyName: FilterKeyName,
    selectionList: CommonSelectionInterface[],
  ) {
    const isAllSelected = selectionList?.length === this[keyName].length;

    if (isAllSelected) return false;

    return this[keyName].length > 0;
  }
  sortChange(sortState: Sort) {
    this.sortDirection = null;
    this.sortIndex = -1;
    if (sortState.direction) {
      this.sortDirection = sortState.direction;
      this.sortIndex = this.displayedColumns.findIndex(
        (x) => x == sortState.active,
      );
    }
    if (this.list.length == 0) return;

    this.getData();
  }
  pageChange(e: PageEvent) {
    this.offset = e.pageIndex + 1;
    this.limit = e.pageSize;
    this.getData();
  }
  toggleSelectAllOptions(
    val: boolean,
    keyName: FilterKeyName,
    selectionList: CommonSelectionInterface[],
  ) {
    this[keyName] = val
      ? selectionList.map((item) => {
          return keyName === "selectedDealerCategories" ? item.name : item.id;
        })
      : [];

    const filterKey =
      keyName === "selectedPartners"
        ? "motorPartnerIds"
        : keyName === "selectedInsurers"
        ? "motorProductIds"
        : "dealerCategories";

    const filterValue = this[keyName];
  }

  resetFilteredOptions(
    keyName: FilterKeyName,
    selectionList: CommonSelectionInterface[],
  ) {
    event.stopImmediatePropagation();
    this.toggleSelectAllOptions(true, keyName, selectionList);
  }

  getMultiFilterLabel() {
    if (this.selectedStatus.length == 3) return "Status";
    else {
      return this.selectedStatus.map((x) => x).join(", ");
    }
  }

  onSelectedOptionsChange() {
    this.getData();
  }

  private getData() {
    this.isLoading = true;
    this.list = [];
    this.length = 0;
    this.promoCodeMotorService
      .getListing(
        this.limit,
        this.offset,
        this.searchText,
        this.sortIndex,
        this.sortDirection,
        this.creationStartDate,
        this.creationEndDate,
        this.effectiveStartDate,
        this.effectiveEndDate,
        this.selectedStatus,
      )
      .subscribe({
        next: (x) => {
          this.isLoading = false;
          this.list = x.data;
          this.length = x.count;
        },
        error: (err) => {
          this.isLoading = false;
          this.alertService.openSnackBar(err.error);
        },
      });
  }
  private getStatusList() {
    this.isLoading = true;
    this.promoCodeMotorService.getStatusList().subscribe({
      next: (x) => {
        this.isLoading = false;
        const data: any = x;
        data.map((x) => {
          this.statusOptions.push(x.name);
        });
        this.getData();
      },
      error: (err) => {
        this.isLoading = false;
        this.alertService.openSnackBar(err.error);
      },
    });
  }
  onSelectSearchChange(searchValue: string = null) {
    if (searchValue) {
      let txt = searchValue.toLowerCase().trim();
      this.filterStatusList = this.statusOptions.filter((x) =>
        x.toLowerCase().includes(txt),
      );
    } else {
      this.filterStatusList = Object.assign([], this.statusOptions);
    }
  }

  resetStatus(event) {
    event.stopPropagation();
    this.selectedStatus = this.statusOptions;
    this.filter();
  }
  downloadTemplate(fileName: string) {
    this.promoCodeMotorService.downloadTemplate().subscribe(
      (blob) => {
        triggerFileDownload(blob, `${fileName}.xlsx`);
      },
      (err) => {
        this.alertService.openSnackBar(`Error: ${err.message}`);
      },
    );
  }

  onExport(fileName: string) {
    this.isLoading = true;
    this.promoCodeMotorService
      .getListingExcel(
        this.limit,
        this.offset,
        this.searchText,
        this.sortIndex,
        this.sortDirection,
        this.creationStartDate,
        this.creationEndDate,
        this.effectiveStartDate,
        this.effectiveEndDate,
        this.selectedStatus,
      )
      .subscribe(
        (blob) => {
          triggerFileDownload(blob, `${fileName}.xlsx`);
        },
        (err) => {
          this.alertService.openSnackBar(`Error: ${err.message}`);
        },
      );
  }

  deactivateUser() {
    let data: ConfirmationDialogModel = {
      title: "Deactivate User",
      content: "Are you sure want to deactivate",
      btnColor: "warn",
      okBtnText: "Deactivate",
    };
    let dialogRef = this.dialog.open(ConfirmationDialog, { data: data });

    dialogRef.afterClosed().subscribe((x) => {
      if (x) {
        this.promoCodeMotorService
          .deactivate(this.selected.id)
          .subscribe((x) => {
            this.alertService.openSnackBar(
              `Promo Code ${this.selected.code} is deactivated`,
            );
            this.getData();
          });
      }
    });
  }

  openStatus() {
    let data: StatusTrackerModel = {
      title: "Status Tracker",
      type: "motor",
    };
    let dialogRef = this.dialog.open(StatusDialogComponent, { data: data });

    dialogRef.afterClosed().subscribe((x) => {
      if (x) {
      }
    });
  }

  uploadFile() {
    const bottomRef = this.bottomSheet.open(UploadFileComponent, {
      backdropClass: "sidenav-container",
      data: {
        type: "motor",
      },
      disableClose: true,
    });
    bottomRef.afterDismissed().subscribe((result) => {
      if (result && result.success) {
        this.getData();
      }
    });
  }
  isSelectedAll() {
    return this.selectedStatus.length == this.statusOptions.length;
  }

  toggleSelectAll(val: boolean) {
    this.selectedStatus = [];
    if (val) {
      this.selectedStatus = this.statusOptions;
    } else {
      this.selectedStatus = [];
    }
    this.filter();
  }
}
export type FilterKeyName =
  | "selectedPartners"
  | "selectedInsurers"
  | "selectedDealerCategories";
